import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Coming Soon"
      description="A next gen web development company."
      image={{ src: "/static/images/hyprlink_logo.svg" }}
    />
    <section className="h-full flex flex-col justify-center content-center items-center">
      <div>
        <StaticImage
          src="../images/hyprlink_logo.svg"
          width={100}
          alt="Hyprlink Logo"
          placeholder="blurred"
        />
      </div>
      <div>
        <StaticImage
          src="../images/hyprlink.png"
          height={60}
          alt="Hyprlink Text"
          placeholder="blurred"
        />
      </div>
      <div className="text-center">
        <h2 className="text-md text-gray-800 font-semibold font-nunitosans">
          Next Gen Web Development
        </h2>
      </div>
      <h1 className="pt-10 text-xl font-bold uppercase text-gray-800 font-nunitosans">
        Coming Soon
      </h1>
    </section>
  </Layout>
)

export default IndexPage
